import React from 'react';
import { formatTime } from '@parm/util';
import { firestore } from 'firebase';
import { NodeEntity } from '../Node/NodeDefinition';

export interface FieldProps<T> {
  value: T,
  node: NodeEntity,
}
type Component<T> = (props: FieldProps<T>) => JSX.Element;
interface Field {
  Time: Component<firestore.Timestamp>,
  Text: Component<string>,
}
export const Field: Field = {
  Time: (
    props: FieldProps<firestore.Timestamp>
  ) => {
    const { value } = props;
    const timeDisplay =
      !value ?
        'None'
        :
        formatTime(
          value.toDate()
        );
    return (
      <span>
        {timeDisplay}
      </span>
    )
  },
  Text: (
    props: FieldProps<string>
  ) => {
    const { value } = props;
    return (
      <span>
        {value}
      </span>
    );
  },
}