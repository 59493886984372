import React from 'react';

import './react-filter-control.scss';

/* eslint-disable-next-line */
export interface ReactFilterControlProps {}

export const ReactFilterControl = (props: ReactFilterControlProps) => {
  return (
    <div>
      <h1>Welcome to react-filter-control component!</h1>
    </div>
  );
};

export default ReactFilterControl;
