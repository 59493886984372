import React from 'react';
import { NodeEntity } from './Node/NodeDefinition';

interface NodeContextProps {
  node: NodeEntity,
}

export const NodeContext = React.createContext<NodeContextProps>({

} as any);

export interface WithNodeProps {
  node: NodeEntity,
}
export function withNode<T>(
  Component: React.FunctionComponent<T & WithNodeProps>
): React.FunctionComponent<T> {
  return (props: T) => (
    <NodeContext.Consumer>
      {context => {
        return (
          <Component 
            {...props}
            node={context.node} 
          />
        );
      }}
    </NodeContext.Consumer>
  );
}