import React from 'react';

import './react-date-subtracter.scss';

/* eslint-disable-next-line */
export interface ReactDateSubtracterProps {}

export const ReactDateSubtracter = (props: ReactDateSubtracterProps) => {
  return (
    <div>
      <h1>Welcome to react-date-subtracter component!</h1>
    </div>
  );
};

export default ReactDateSubtracter;
