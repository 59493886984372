import { debounce } from '@parm/util';
import React, { useEffect, useState } from 'react';
import { OnInputTextEvent } from './TextEditor';

interface TextDisplayProps {
  eventId: string,
}
export const TextDisplay = (props: TextDisplayProps) => {
  const {
    eventId
  } = props;

  const [data, setData] = useState('');

  const setText = (event: CustomEvent<OnInputTextEvent>) => {
    setData(event.detail.value || '');
  }

  useEffect(debounce({
    wait: 150,
    func: () => {
      window.addEventListener(eventId, setText);
      return () => {
        window.removeEventListener(eventId, setText);
      };
    }
  }), [eventId]);

  return (
    <span>
      {data}
    </span>
  );
}