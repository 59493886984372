import React, { useState } from 'react';
import { EventInputProps, withEvent, WithEventInputProps } from './WithEventInput';

type TestHeightProps = EventInputProps & {
  testValue: any,
  textIfTrue: string,
  textIfFalse: string,
}

export const TestHeight = withEvent((props: TestHeightProps & WithEventInputProps) => {
  const {
    value = ''
  } = props;
  const feetRegex = /^(\d)' (\d\d?)\"$/g;
  let height = 0;
  const _value: string = value.trim();
  const match = feetRegex.exec(_value);
  if (match) {
    const [_, feet, inches] = match;
    let ft;
    let ins;
    try {
      ft = parseInt(feet);
      ins = parseInt(inches);
    } catch (e){
      console.error(
        `[ERROR]: match '${feet}' or match '${inches}' threw error during parseInt.`,
        new Error().stack,
        e,
      );
    }
    height = ft * 12 + ins;
  }
  return height > 0 ? (
    <span>{height < 72 ? 'he is a manlet' : 'he is a regular chad thundercock'}</span>
  ) : (
    <span>prepare yourself</span>
  );
});

/** fun note on regex:
 * 
 * > As MDN has stated, invoking test on a
 * > regex with flag “global” set would 
 * > set “lastIndex” on the original regex. 
 * > That lastIndex allows user to continue test the original 
 * > string since there may be more than one matched 
 * > patterns. This is affecting the exec that comes along.
 * 
 * > So the proper usage of a regex with “g”
 * > flag set is to continue executing the 
 * > regex until it returns null, which means no 
 * > other pattern could be found in the string.
 * 
 * I discovered this because previous i did
 * ```
 * if regex.test(...) then regex.exec(...)
 * ```
 * as such by the time i did my `exec` the 
 * $lastIndex was at the end of my string.
 * 
 * source:  https://ikely.me/2019/07/10/regexp-test-returns-true-but-regexp-exec-returns-null/
 */