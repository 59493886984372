import React, { useEffect, useState } from 'react';
import { environment } from '../environments/environment';
import Typography from '@material-ui/core/Typography';
import { LoadingSpinner } from './LoadingSpinner';
import { useStyles } from './useStyles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useData } from './firebase';
import { EndMessage } from './EndMessage';
import { useQueryParams, StringParam } from 'use-query-params'; 
import SideBar from './SideBar';
import { useFilter } from '@parm/react/filter-control';
import CardContent from '@material-ui/core/CardContent';
import { storage } from './storage';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { useSortState } from './hooks';
import Markdown from 'markdown-to-jsx';
import { AdventureOptionCard } from './AdventureOptionCard';
import { useRouteMatch } from 'react-router-dom';
import { NodeEntity } from './Node/NodeDefinition';


const compareByTime = (a: NodeEntity, b: NodeEntity) => {
  const result = +a.createTime.toDate() - +b.createTime.toDate();
  return storage.sort() ? result : -result;
}

export default function Adventure(props) {
  const classes = useStyles();
  const [afterSize, setAfterSize] = useState(4);
  const [beforeSize, setBeforeSize] = useState(1);
  const match = useRouteMatch('/nodes/:id');
  const focus = match && match.params['id'] || 'create';
  const { 
    state: data, setCurrent: setCurrentState,
    createOption, updateNode,
  } = useData();
  const fetchAfter = () => 
    setAfterSize(afterSize + 3); 
  const fetchBefore = () => 
    setBeforeSize(beforeSize + 3); 

  const { filter, control } = useFilter();

  const feedFilter = useFeedFilter(data.nodes);
  const rootId = data.root && data.root.id;
  const nodes: NodeEntity[] = data.nodes
    .filter(feedFilter)
    .filter(v => filter(v.text))
    .sort(compareByTime)
    ;
  const focusNode = nodes.find(n => n.id === focus);

  const { toggleSort, sortAscending } = useSortState();

  return (
    <div className={classes.paper}>
      <SideBar/>
      <Typography component="h1" variant="h5">
        <Markdown>
          {environment.header}
        </Markdown>
      </Typography>
      <Typography>
        {environment.metaDescription && (
            <Markdown>
              {environment.metaDescription}
            </Markdown>
        )}
      </Typography>
        <div className={classes.cards}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                {control}
              </Grid>
              <Grid item xs={2}>
                oldest first
                <Switch checked={sortAscending} onChange={toggleSort}/>
              </Grid>
            </Grid>
          </CardContent>
          {focusNode && (
            <AdventureOptionCard 
              createOption={updateNode}
              key={'focus'}
              {...focusNode}
              prev
            />
          )}
          <InfiniteScroll
            dataLength={afterSize}
            next={fetchAfter}
            hasMore={afterSize < nodes.length}
            loader={<LoadingSpinner/>}
            endMessage={<EndMessage/>}
          >
            {nodes.slice(0, afterSize)
              .filter(node => !focusNode || node.id !== focusNode.id)
              .map((node, i) => {
              return (
                <AdventureOptionCard
                  createOption={updateNode}
                  key={'after-' + i}
                  {...node}
                  prev
                />
              )
            })}
          </InfiniteScroll>
        </div>
    </div>
  );
}

/**
 * returns a feed fitler based
 * on the current route.
 * returns a filte that always returns
 * true if no feed filter is found
 */
const useFeedFilter = (nodes) => {
  const feedMatch = useRouteMatch('/feeds/:feed');
  const feed = feedMatch && feedMatch.params['feed'];
  let feedFilter;
  if (feedMatch) {
    feedFilter =
      nodes.find(n => {
        const parts = n.id.split('.');
        if (parts.length !== 3)
          return false;
        if (parts[1] !== 'feeds')
          return false;
        if (parts[2] !== feed)
          return false;
        return true;
      }
      );
  }
  if (feedFilter) {
    return n => {
      const [
        { left, op, right }
      ] = feedFilter.data.filter;
      switch (op) {
        case '!=':
          return n[left] !== right;
        default:
          return true;
      }
    };
  }

  return () => true;
}