// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { Environment } from './environment.interface';

export const environment: Environment = {
  "archetype": "blog",
  "maxResponses": 1,
  "header": "patryc for tempe city council",
  "host": "patrycfortempe",
  "origin": "patrycfortempe.com",
  "metaDescription": "community comes first",
  "app": "patryc-for-tempe",
  "stage": "prod",
  "numResponses": 1,
  "title": "patryc for tempe city council",
  "metaTitle": "patryc for tempe city council"
};