import React from 'react';
import { debounce } from '@parm/util';
import { useEffect, useState } from 'react';
import { OnInputTextEvent } from './TextEditor';

export interface EventInputProps {
  eventId: string,
}
export interface WithEventInputProps {
  value: any,
}
export function withEvent<T extends EventInputProps>(
  Component: React.FunctionComponent<T & WithEventInputProps>
): React.FunctionComponent<T> {
  return (props: T) => {
    const {
      eventId
    } = props;

    const [data, setData] = useState('');

    const setText = (event: CustomEvent<OnInputTextEvent>) => {
      setData(event.detail.value || '');
    }

    useEffect(debounce({
      wait: 150,
      func: () => {
        window.addEventListener(eventId, setText);
        return () => {
          window.removeEventListener(eventId, setText);
        };
      }
    }), [eventId]);

    return (
      <Component
        {...props}
        value={data}
      />
    );
  }
}