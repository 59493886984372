import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useField } from '@parm/react/use-field';
import { useStyles } from './useStyles';
import { NodeContext } from './NodeContext';
import { useData } from './firebase';
import { debounce } from '@parm/util';

interface Setting {
  path: string[],
  title: string,
  description: string,
  value: any,
}

interface SettingEditorProps {
  id: string,
  setting: Setting,
  route: string[],
}

const SettingEditor = (props: SettingEditorProps) => {
  const { 
    setting, id, route: routeArr 
  } = props;
  const classes = useStyles();
  const { updateNode } = useData();
  const { value, field } = useField({
    label: setting.title,
    value: setting.value,
  });
  const route = routeArr.join('/');
  const path = setting.path.join('.');
  const qualifiedPath = [route, path].join('/');
  useEffect(debounce({ wait: 500, func: () => {
    updateNode({
      id,
      data: {
        settings: {
          [path]: {
            ...setting,
            value,
          },
        }
      }
    });
  }}), [value]);
  return (
    <div key={qualifiedPath}>
      <h4>
        {path}
      </h4>
      <div>
        {field}
      </div>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.quote}
        component="div"
      >
        {setting.description}
      </Typography>
    </div>
  );
}

export const Settings = () => {
  /**
   * example setting, do not delete
   */
  let data = [{
    route: ['admin', 'settings'],
    path: ['color', 'accent'],
    title: 'accent color',
    description: 'change the accent color for the website',
    value: '',
  }];

  return (
    <NodeContext.Consumer>
      {context => {
        if (!context.node) {
          return (<></>);
        }
        if (!context.node.data) {
          return (
            <span>
              Error: Settings node is missing `data` property.
            </span>
          );
        }
        if (!context.node.data.route) {
          return (
            <span>
              Error: Settings node is missing `data.route` property.
            </span>
          );
        }
        const node = context.node;
        const data = node.data;
        const settings = data.settings;
        const route = data.route.join('/');

        return Object.keys(settings)
          .map(settingKey => settings[settingKey])
          .map(setting => {
            const path = setting.path.join('.');
            const qualifiedPath = [route, path].join('/');
            return (
              <SettingEditor 
                key={qualifiedPath}
                id={node.id}
                route={data.route}
                setting={setting}
              />
          )});
      }}
    </NodeContext.Consumer>
  );
};