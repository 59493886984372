import React, { useEffect } from 'react';
import { useField } from '@parm/react/use-field';
import { debounce } from '@parm/util';
import {  withNode, WithNodeProps } from './NodeContext';

export interface OnInputTextEvent {
  value: string,
}
interface TextEditorProps {
  initialValue?: string,
  label: string,
  fieldId: string,
  placeholder?: string,
}
export const TextEditor = withNode((
  props: TextEditorProps & WithNodeProps
) => {
  const {
    initialValue = '',
    label, fieldId, node,
    placeholder,
  } = props;
  const {
    field, value
  } = useField({
    label, 
    value: initialValue,
    placeholder,
  });
  useEffect(() => {
      window.dispatchEvent(new CustomEvent<OnInputTextEvent>(
        `${node.id}.${fieldId}.oninput`,
        { detail: { value }}
      ));
    }
  , [value, node.id]);
  return (
    field
  );
});
